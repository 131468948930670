<template>
  <div class="table">
    <table class="table__list">
      <tr>
        <th @click="sort('sro_registration_number')">
          <div>
            <sort-icon :sort="sortBy.sro_registration_number"/>
            <div>
              Рег. номер СРО
            </div>
          </div>
        </th>
        <th @click="sort('sro_full_description')">
          <div>
            <sort-icon :sort="sortBy.sro_full_description"/>
            <div>Наименование СРО</div>
          </div>
        </th>
        <th @click="sort('member_status')">
          <sort-icon :sort="sortBy.member_status"/>
          Текущий статус члена
        </th>

        <th @click="sort('full_description')">
          <div>
            <sort-icon :sort="sortBy.full_description"/>
            <div>Наименование члена СРО</div>
          </div>
        </th>

        <th @click="sort('inn')">
          <div>
            <sort-icon :sort="sortBy.inn"/>
            <div>
              ИНН
            </div>
          </div>
        </th>

        <th @click="sort('ogrnip')">
          <div>
            <sort-icon :sort="sortBy.ogrnip"/>
            <div>
              ОГРН/ОГРИП
            </div>
          </div>
        </th>

         <th @click="sort('registry_registration_date')">
          <div>
            <sort-icon :sort="sortBy.registry_registration_date"/>
            <div>
              Дата регистрации в реестре СРО
            </div>
          </div>
        </th>

        <th @click="sort('director')">
          <div>
            <sort-icon :sort="sortBy.director"/>
            <div>
              Руководитель
            </div>
          </div>
        </th>

      <tr
        v-for="member in list"
        :key="'member-' + member.id"
        @click="showMember(member.id)"
      >
        <td>{{ member.sro.registration_number }}</td>
        <td>{{ member.sro.full_description }}</td>
        <td>
          {{ member.sro.deactivate_message ? '' : member.member_status.title }}</td>
        <td v-html="highlight(member.full_description)" />
        <td v-html="highlight(member.inn)" />
        <td v-html="highlight(member.ogrnip)" />
        <td>{{ formatDateShort(member.registry_registration_date) }}</td>
        <td>{{ member.director }}</td>
      </tr>
    </table>
    <div class="card-list">
      <div
        class="card"
        v-for="member in list"
        :key="'member-card-' + member.id"
      >
        <div class="card__header">
          <div>
            <div
              class="status"
              :class="{
                'status-empty': member.sro.deactivate_message,
                'status-enabled': member.member_status.code === $options.STATUS_ENABLED.id,
                'status-disabled': member.member_status.code === $options.STATUS_DISABLED.id
              }"
            ></div>
          </div>
          <div
            v-html="highlight(member.short_description)"
          />
          <div class="status"></div>
        </div>

        <div class="card__title">
          Наименование СРО:
        </div>
        <div class="card__value">
          {{ member.sro.full_description }}
        </div>

        <div class="card__title">
          Регистрационный номер в реестре СРО:
        </div>
        <div class="card__value">
          {{ member.registration_number }}
        </div>

        <div class="card__title">
          Полное наименование:
        </div>
        <div class="card__value"
             v-html="highlight(member.full_description)" />

        <div v-if="!member.sro.deactivate_message">
          <div class="card__title">
            Статус:
          </div>
          <div class="card__value">
            {{ member.member_status.title }}
          </div>
        </div>

        <div class="card__title">
          Дата внесения в реестр СРО:
        </div>
        <div class="card__value">
          {{ formatDate(member.registry_registration_date) }}
        </div>

        <div class="card__title">
          ОГРН/ОГРН:
        </div>
        <div class="card__value"
             v-html="highlight(member.ogrnip)" />

        <div class="card__title">
          ИНН:
        </div>
        <div class="card__value"
             v-html="highlight(member.inn)" />

        <div class="card__title">
          Руководитель:
        </div>
        <div class="card__value">
          {{ member.director }}
        </div>

        <v-btn
          class="mt-5"
          color="secondary"
          @click="showMember(member.id)"
        >
          Подробнее
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import MemberTableMixin from '../../mixins/TableMixin'
import { MEMBER_STATUS_ENABLED, MEMBER_STATUS_DISABLED } from '../../helpers/dictionary'

export default {
  name: 'MemberTableByAllSro',
  mixins: [MemberTableMixin],
  STATUS_ENABLED: MEMBER_STATUS_ENABLED,
  STATUS_DISABLED: MEMBER_STATUS_DISABLED,
  methods: {
    showMember (id) {
      this.$router.push({
        name: 'MemberItem',
        params: { id }
      })
    }
  }
}
</script>

<style scoped>

</style>
