<template>
  <div>
    <div class="breadcrumbs">
      <span>Реестр членов СРО</span>
    </div>
    <div
      class="page-title"
    >
<!--      Реестр членов СРО в области строительства-->
    </div>
    <control-panel
      label-search="ИНН, ОГРН, Наименование"
      v-bind:search-string.sync="options.searchString"
      :chips="chips"
      @show-filter="filterIsShow = true"
      @show-sort="sortIsShow = true"
      @submit-search="submitSearch"
      @delete-filter="deleteFilter"
    />
    <div
      style="position: relative"
      v-if="!errorMessage"
    >
      <sort-form
        :value="options.sortBy"
        :sort-options="filterOptions"
        :sort-is-show.sync="sortIsShow"
        @submit="submitSort"
      />
      <filter-form
        :filter-options="filterOptions"
        :filters="options.filters"
        :filter-is-show.sync="filterIsShow"
        @submit-filter="submitFilter"
      />
      <div
        v-if="list"
      >
        <div v-if="list.length > 0">
          <member-table-by-all-sro
            :search-string="options.searchString"
            :sort-by="options.sortBy"
            :list="list"
            @sort="sortBy"
          />
          <pagination
            :page-count="countPage"
            :page="page"
            :count-all="count"
            :max-count-on-page="options.pageCount"
            @check-page="setPage"
            @change-max-count="changeMaxCount"
          />
        </div>
        <div
          class="message"
          v-else
        >Записи, удовлетворяющие критериям поиска отсутствуют</div>
      </div>
      <spinner
        v-else-if="spinnerIsShow"
      />
    </div>
    <error-message
      v-else
    >{{ errorMessage }} </error-message>
  </div>
</template>

<script>
import ListMixin from '../../mixins/ListMixin'
import { getDictionaries, getMemberList } from '../../helpers/api'
import { loadMemberListByAllSroOptions, saveMemberListByAllSroOptions } from '../../helpers/history'
import { FILTER_TYPE_DATE, FILTER_TYPE_EQ, FILTER_TYPE_LIKE } from '../../helpers/dictionary'
import FilterForm from '../../components/filter/FilterForm'
import MemberTableByAllSro from '../../components/member/MemberTableByAllSro'
import ControlPanel from '../../components/ControlPanel'
export default {
  name: 'MemberListByAllSro',
  mixins: [ListMixin],
  components: {
    FilterForm,
    ControlPanel,
    MemberTableByAllSro
  },
  data () {
    return {
      filterOptions: [
        {
          name: 'region_number',
          title: 'Субъект РФ',
          type: FILTER_TYPE_EQ,
          values: []
        },
        {
          name: 'sro_registration_number',
          title: 'Регистрационный номер СРО',
          type: FILTER_TYPE_LIKE
        },
        {
          name: 'sro_full_description',
          title: 'Наименование СРО',
          type: FILTER_TYPE_LIKE
        },
        {
          name: 'member_status',
          title: 'Текущий статус члена',
          type: FILTER_TYPE_EQ,
          values: []
        },
        {
          name: 'full_description',
          title: 'Наименование члена СРО',
          type: FILTER_TYPE_LIKE
        },
        {
          name: 'inn',
          title: 'ИНН',
          type: FILTER_TYPE_LIKE
        },
        {
          name: 'ogrnip',
          title: 'ОГРН/ОГРНИП',
          type: FILTER_TYPE_LIKE
        },
        {
          name: 'registry_registration_date',
          title: 'Дата регистрации в реестре СРО',
          type: FILTER_TYPE_DATE
        },
        {
          name: 'director',
          title: 'ФИО руководителя',
          type: FILTER_TYPE_LIKE
        }
      ]
    }
  },
  methods: {
    async getDataList () {
      if (this.options.filters.member_status && this.options.filters.member_status === 1) {
        this.options.filters.sro_enabled = true
      } else {
        delete this.options.filters.sro_enabled
      }
      return await getMemberList(this.options)
    },
    saveOptions: saveMemberListByAllSroOptions,
    loadOptions: loadMemberListByAllSroOptions,
    async getDictionaries () {
      const data = await getDictionaries({ dictionaries: ['member_statuses', 'region_number'] })

      if (!data.success || !data.data) {
        if (data.message) {
          this.errorMessage = data.message
        } else {
          this.errorMessage = 'Непредвиденная ошибка сервера'
        }
        return
      }
      this.statusList = data.data.member_statuses
      this.regionList = data.data.region_number
      this.filterOptions.find(el => el.name === 'member_status').values = this.statusList
      this.filterOptions.find(el => el.name === 'region_number').values = this.regionList
    }
  }
}
</script>

<style scoped lang="sass">
</style>
